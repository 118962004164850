import "./style.css"
import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"
import { MeshMatcapMaterial, TextureLoader } from "three"

const canvas = document.querySelector("canvas.webgl")
const scene = new THREE.Scene()
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
}
const donuts = []

//Loaders
const fontLoader = new FontLoader()
const textureLoader = new TextureLoader()

//Textures y materials
const matcapTexture = textureLoader.load(
  "/resources/textures/matcaps/matcap_green.png"
)
const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })

// Objetos
const torusGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45)

for (let i = 0; i < 100; i++) {
  const donut = new THREE.Mesh(torusGeometry, material)

  donut.position.x = (Math.random() - 0.5) * 10
  donut.position.y = (Math.random() - 0.5) * 10
  donut.position.z = (Math.random() - 0.5) * 10

  donut.rotation.x = Math.random() * Math.PI
  donut.rotation.y = Math.random() * Math.PI

  let randomScale = Math.random()
  donut.scale.set(randomScale, randomScale, randomScale)

  donuts.push(donut)

  scene.add(donut)
}

//Texto
fontLoader.load("/resources/fonts/gentilis_reg.json", (font) => {
  console.log("fuente cargada")
  const textGeometry = new TextGeometry(
    "HOLA,\nEsto es una \nprueba de concepto",
    {
      font,
      size: 0.4,
      height: 0.2,
      curveSegments: 12,
      bevelEnabled: true,
      bevelThickness: 0.03,
      bevelSize: 0.02,
      bevelOffset: 0,
      bevelSegments: 5,
    }
  )

  const text = new THREE.Mesh(textGeometry, material)
  scene.add(text)
  textGeometry.center()
})

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight

  // Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Camara
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
)
camera.position.z = 10
scene.add(camera)

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
scene.controls = controls

const animate = () => {
  controls.update()
  renderer.render(scene, camera)

  // Giro de los torus
  donuts.forEach((donut) => (donut.rotation.x += 0.01 * Math.random()))
  // Acercar camara al centro
  if (camera.position.z > 3) camera.position.z -= 0.1

  window.requestAnimationFrame(animate)
}

animate()
